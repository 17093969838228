import React from 'react';
import HeaderProvider from './src/context/HeaderContext';

export const wrapRootElement = ({ element }) => (
  <HeaderProvider>{element}</HeaderProvider>
);

const lunr = require('lunr');

export const onClientEntry = () => {
  window.__LUNR__ = window.__LUNR__ || {};
  window.__LUNR__.__loaded = fetch(`${__PATH_PREFIX__}/search_index.json`)
    .then(function(response) {
      return response.json();
    })
    .then(function(fullIndex) {
      window.__LUNR__ = Object.keys(fullIndex).reduce(
        (prev, key) => ({
          ...prev,
          [key]: {
            index: lunr.Index.load(fullIndex[key].index),
            store: fullIndex[key].store
          }
        }),
        {
          __loaded: window.__LUNR__.__loaded
        }
      );
      return window.__LUNR__;
    })
    .catch(e => console.log('Failed fetch search index'));
};
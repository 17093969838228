import React, { Component } from 'react';

export const HeaderContext = React.createContext();

export default class HeaderProvider extends Component {
  state = {
    logoColor: '',
    initLogoColor: '',
    visibleBackToBlog: false,
    visibleUI: true,
    path: '',
    videoBgPaused: true,
    heroVideoPlaying: false,
    category: ''
  };
  render() {
    return (
      <HeaderContext.Provider
        value={{
          state: this.state,
          setLogoLight: () =>
            this.setState({
              logoColor: 'light'
            }),
          setLogoDark: () =>
            this.setState({
              logoColor: 'dark'
            }),
          resetLogoLight: () =>
            this.setState({
              initLogoColor: 'light',
              logoColor: 'light'
            }),
          resetLogoDark: () =>
            this.setState({
              initLogoColor: 'dark',
              logoColor: 'dark'
            }),
          showBackToBlog: () =>
            this.setState({
              visibleBackToBlog: true
            }),
          hideBackToBlog: () =>
            this.setState({
              visibleBackToBlog: false
            }),
          setVisibleUI: visible =>
            this.setState({
              visibleUI: visible
            }),
          setPath: path =>
            this.setState({
              path: path
            }),
          setCategory: category =>
            this.setState({
              category: category
            })
        }}
      >
        {this.props.children}
      </HeaderContext.Provider>
    );
  }
}
